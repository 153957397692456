import React, { useEffect, useState, useRef } from 'react';
import { PuffLoader } from 'react-spinners';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { useNavigate } from 'react-router-dom';
function Kyc() {
    const navigate = useNavigate()
    const [kycUrl, setKycUrl] = useState(null);
    const [loading, setLoading] = useState(true);
   // const hasFetched = useRef(false); // Track whether the effect has run before

    //useEffect(() => {
    //    const fetchKycUrl = async () => {
    //        if (hasFetched.current) return; // Prevent double fetch
    //        hasFetched.current = true; // Mark as fetched
            
    //        try {
    //            const response = await authFetch('/api/v1/user/kyc/url', {
    //                method: 'POST',
    //            });
    //            console.log("response",response);
                
    //            if (response?.code === 200) {
    //                setKycUrl(response?.data?.kyc_url);
    //            } else {
    //                showToast(response.error, "error");
    //                //navigate('/profile') // Navigate back to profile if the KYC URL fails to load
    //            }
    //        } catch (err) {
    //            console.error('Error fetching KYC URL:', err);
    //            showToast('An error occurred while fetching the KYC URL.', 'error');
    //            //navigate('/profile') // Navigate back to profile if error
    //        } finally {
    //            setLoading(false);
    //        }
    //    };

    //    fetchKycUrl();
    //}, []);

    return (
        <div className="flex flex-col justify-center items-center h-full w-full">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <PuffLoader color="#28d849" size={60} />
                </div>
            ) : (
                <div className="relative w-full h-full overflow-hidden">
                    {/*<iframe
                        src={kycUrl}
                        title="KYC Verification"
                        style={{
                            height: '70vh',
                            width: '100%',
                            border: 'none',
                            position: 'relative',
                            transform: 'translateY(-80px)',  // Move the iframe content up
                        }}
                    />*/}
                </div>


            )}
        </div>
    );
}

export default Kyc;
