import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authFetch from "../utils/authFetch";
import useCheckOnBoardStore from '../store/CheckOnBoardStore';
import useProfileStore from '../store/profileStore';
const CheckOnboard = () => {
    const navigate = useNavigate();
    const { setProfile } = useProfileStore()
    const { setPhoneAdded, setBankAdded, setAadhaarAdded } = useCheckOnBoardStore();

    useEffect(() => {
        const fetchData = async () => {
            const response = await authFetch(`/api/v1/user/checkPhoneBank`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                const isPhoneAdded = response.data?.isPhoneAdded;
                const isBankAdded = response.data?.isBankAdded;
                const isKycCompleted = response.data?.userKycData?.isKycCompleted;

                setPhoneAdded(isPhoneAdded);
                setBankAdded(isBankAdded);
                setAadhaarAdded(isKycCompleted);

                if (!isPhoneAdded) {
                    setProfile();
                    navigate("/addPhone");
                    return;
                }
                
                if(!isKycCompleted) {
                    setProfile();
                    navigate("/addAadhaar");
                    return;
                }

                if (!isBankAdded) {
                    setProfile();
                    navigate("/add-bank");
                    return;
                }

                if (isPhoneAdded && isBankAdded && isKycCompleted) {
                    setProfile();
                    navigate("/sell");
                }
            }
        };

        fetchData();
    }, [navigate, setPhoneAdded, setBankAdded, setAadhaarAdded]);

    return null; // This component does not render anything
};

export default CheckOnboard;