import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useForgetPasswordStore from '../store/ForgetPasswordStore';
import useSignUpStore from '../store/SignupStore';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import CheckOnboard from '../utils/checkOnBoard';
import useLoginStore from '../store/LoginStore';
import usePhoneStore from '../store/addPhoneStore';
import useAadhaarStore from '../store/addAadhaarStore';
const OTPVerification = () => {
    const location = useLocation()
    const type = location.state?.type;
    const referenceId = location.state?.id;
    const { globalAadhaar, clearGlobalAadhaar } = useAadhaarStore();
    const { globalEmail, globalNewPassword, clearGlobalEmail, clearGlobalNewPassword } = useForgetPasswordStore();
    const { globalEmail: globalSignupEmail, globalPassword, clearGlobalEmail: clearGlobalSignupEmail, clearGlobalPassword } = useSignUpStore()
    const { globalEmail: globalLoginEmail, globalPassword: globalLoginPassword, clearGlobalEmail: clearGlobalLoginEmail, clearGlobalPassword: clearGlobalLoginPassword } = useLoginStore()
    const { globalPhone, clearGlobalPhone, globalSelectedCountry, clearGlobalSelectedCountry } = usePhoneStore()
    const [otp, setOtp] = useState(type === "addAadhaar" ? ["", "", "", "", "", ""] : ["", "", "", ""]);
    const [email, setEmail] = useState(''); // Example email, change dynamically if needed
    const [timer, setTimer] = useState(60); // 60 seconds countdown
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // Add a state for form submission
    const [showCheckOnboard, setShowCheckOnboard] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setEmail(type === "forgetPassword" ? globalEmail : type === "login" ? globalLoginEmail : globalSignupEmail);
    }, [globalEmail, globalSignupEmail, globalLoginEmail]);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer]);
    // Function to handle OTP input changes
    const handleChange = (element, index) => {
        if (!isNaN(element.value) && element.value !== "") {
            let newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            // Focus to next input
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    };

    // Resend OTP function
    const handleResendOtp = async () => {
        try {
            setIsResendDisabled(true);
            setTimer(60);
            let url = '';
            let response = '';
            if (type === "addAadhaar") {
                let body = {
                    aadharNumber: globalAadhaar,
                };
                url = `/api/v1/user/kyc/otp`
                response = await authFetch(url, {
                    method: 'Post',
                    body: body,
                })
            } else {
                url = type === "forgetPassword" ? `/api/v1/user/forgetOtp?email=${globalEmail}` : type === "login" ? `/api/v1/user/loginOtp?email=${globalLoginEmail}` : type === "addPhone" ? `/api/v1/user/sendAddPhoneOtp?phone=${globalSelectedCountry?.dialCode}${globalPhone}` : `/api/v1/user/signupOtp?email=${globalSignupEmail}`
                response = await authFetch(url, {
                    method: 'GET',
                });
            }

            if (response?.code === 200) {
                showToast(`OTP is sent to your ${type === "addPhone" ? "Phone" : "Email"}`, "success");
            } else {
                showToast(response?.error || "Failed to send OTP", "error");
            }
        } catch (error) {
            showToast("An error occurred. Please try again.", "error");
        }
    };

    // Submit OTP for verification
    const handleSubmitForgetPassword = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text

            let body = {
                email: globalEmail,
                otp: otpValue,
                newPassword: globalNewPassword
            };
            const response = await authFetch(`/api/v1/user/changeForgetPassword`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                showToast(response?.message, "success");
                clearGlobalEmail();
                clearGlobalNewPassword();
                navigate("/login");
            } else {
                showToast(response?.error, "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };
    // Submit OTP for verification
    const handleSubmitForSignup = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text

            let body = {
                emailId: globalSignupEmail,
                otp: otpValue,
                password: globalPassword
            };
            const response = await authFetch(`/api/v1/user/signup`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                localStorage.setItem("userToken", response.data?.token);
                setShowCheckOnboard(true); // Show CheckOnboard component
                showToast(response?.message, "success");
                clearGlobalSignupEmail();
                clearGlobalPassword();
            } else {
                showToast(response?.error, "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };

    // Submit OTP for verification
    const handleSubmitForLogin = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text

            let body = {
                emailId: globalLoginEmail,
                otp: otpValue,
                password: globalLoginPassword
            };
            const response = await authFetch(`/api/v1/user/login`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                localStorage.setItem("userToken", response.data);
                setShowCheckOnboard(true); // Show CheckOnboard component
                showToast(response?.message, "success");
                clearGlobalLoginEmail();
                clearGlobalLoginPassword();
            } else {
                // showToast(response?.error, "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };

    // Submit OTP for verification
    const handleSubmitForAddPhone = async () => {
        const otpValue = otp.join("");

        if (otpValue.length === 4) {
            setIsSubmitting(true); // Disable the button and show "Verifying..." text
            let body = {
                phone_number: `${globalSelectedCountry?.dialCode}${globalPhone}`,
                otp: otpValue,
            };
            const response = await authFetch(`/api/v1/user/add/phone`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                setShowCheckOnboard(true); // Show CheckOnboard component
                showToast(response?.message, "success");
                clearGlobalPhone();
                clearGlobalSelectedCountry();
            } else {
                showToast(response?.error || "Something went wrong", "error");
                setOtp(["", "", "", ""]);
                setIsResendDisabled(false);
            }
        } else {
            showToast("Incomplete OTP", "error");
        }

        setIsSubmitting(false); // Enable the button back after request is done
    };

    // Submit OTP for verification
    const handleSubmitForAadhaar = async () => {
        const otpValue = otp.join("");
        setIsSubmitting(true); // Disable the button and show "Verifying..." text
        try {
            let body = {
                aadharNumber: globalAadhaar,
                referenceId: referenceId,
                otp: otpValue
            };

            const response = await authFetch(`/api/v1/user/kyc/verifyOtp`, {
                method: 'POST',
                body: body
            });

            if (response?.code === 200) {
                setShowCheckOnboard(true); // Show CheckOnboard component
                showToast(response?.message, "success");
                clearGlobalAadhaar();
            } else {
                throw new Error(response?.error || "Something went wrong");
            }

        } catch (error) {
            showToast("Invalid OTP.Please try again");
            setOtp(["", "", "", "", "", ""]);
            setIsResendDisabled(false);
        } finally {
            setIsSubmitting(false); // Enable the button back after request is done
        }
        setIsSubmitting(false); // Enable the button back after request is done
    }

    // Keydown handler for Backspace and Delete keys
    const handleKeyDown = (e, index) => {
        if ((e.key === "Backspace" || e.key === "Delete") && otp[index] === "") {
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        } else if (e.key === "Backspace" || e.key === "Delete") {
            let newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        } else if (e.key === "Enter" && otp.join("").length === 4 && !isSubmitting) {
            (type === "forgetPassword" ? handleSubmitForgetPassword : type === "login" ? handleSubmitForLogin : type === "addPhone" ? handleSubmitForAddPhone : handleSubmitForSignup)();
        }
    };
    // Function to handle OTP paste event
    const handlePaste = (e, currentIndex) => {
        e.preventDefault(); // Prevent the default paste behavior
        const paste = e.clipboardData.getData("text").trim(); // Get the pasted text
        if (/^\d{1,4}$/.test(paste)) { // Ensure only 1 to 4 digits are pasted
            const pasteArray = paste.split(""); // Split pasted value into digits
            let newOtp = [...otp]; // Clone the current OTP array

            // Fill the OTP inputs starting from the current index
            for (let i = 0; i < pasteArray.length; i++) {
                if (currentIndex + i < 4) {
                    newOtp[currentIndex + i] = pasteArray[i]; // Fill forward
                }
            }

            setOtp(newOtp); // Update the OTP state
        } else {
            showToast("Invalid OTP format. Please paste up to 4 digits.", "error");
        }
    };


    return (
        <div className="h-[570px] flex flex-col" onKeyDown={(e) => handleKeyDown(e)}>
            <div className="flex flex-col items-center flex-grow">
                <div className="bg-white p-8 rounded-lg w-full max-w-sm">
                    <h1 className="text-xl font-bold text-center mb-4">Enter the verification code</h1>
                    <p className="text-center text-sm text-gray-500 mb-8">
                        We've sent a code to <span className="font-bold">{type === "addPhone" ? `${globalSelectedCountry?.dialCode}${globalPhone}` : type === "addAadhaar" ? "your Aadhaar-linked phone number" : email}</span>
                    </p>

                    <div className="flex justify-between mb-6">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                inputMode="numeric" // Ensures numeric keyboard is displayed
                                pattern="[0-9]*" // Prevents non-numeric input
                                type="text"
                                className="w-12 h-14 text-center border border-gray-300 rounded-lg text-lg focus:ring focus:ring-green-200 outline-none"
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={(e) => handlePaste(e, index)} // Pass the current index to the onPaste handler
                            />
                        ))}
                    </div>
                    <div className="text-center text-sm text-gray-500 mt-4">
                        {isResendDisabled ? (
                            <p>You can resend the verification code in 00:{timer < 10 ? `0${timer}` : timer}</p>
                        ) : (
                            <div className="flex justify-center items-center">
                                <span className="mr-2">Didn't receive code?</span>
                                <button
                                    onClick={handleResendOtp}
                                    className="text-blue-600 font-semibold hover:underline"
                                    disabled={isResendDisabled}
                                >
                                    Resend
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Fixed Button at Bottom */}
            <div className="p-4">
                <button
                    onClick={type === "forgetPassword" ? handleSubmitForgetPassword : type === "login" ? handleSubmitForLogin : type === "addPhone" ? handleSubmitForAddPhone : type === "addAadhaar" ? handleSubmitForAadhaar : handleSubmitForSignup}
                    className={`w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200 ${(type === "addAadhaar" ? otp.join("").length !== 6 : otp.join("").length !== 4) || isSubmitting ? 'opacity-50' : ''}`}
                    disabled={(type === "addAadhaar" ? otp.join("").length !== 6 : otp.join("").length !== 4) || isSubmitting}
                >
                    {isSubmitting ? "Verifying..." : "Verify"}
                </button>
            </div>
            {showCheckOnboard && <CheckOnboard />} {/* Conditionally render CheckOnboard */}
        </div>
    );
};

export default OTPVerification;
