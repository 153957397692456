import { useState, useEffect } from "react";
import "./index.css";
import Widget from "./components/Widget";
import bgImage from './assets/background.jpeg';
import PuffLoader from "react-spinners/PuffLoader";
import Toast from './utils/toast';
import { FaArrowLeft } from 'react-icons/fa';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState('');
  const [type, setType] = useState('offramp');

  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const typeParam = params.get('type');
        setType(typeParam || 'offramp');

        if (!typeParam) {
          window.history.replaceState(null, '', '/?type=offramp');
        }
      } catch (error) {
        console.error('Error fetching IP or country:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIpAndCountry();
  }, []);

  if (loading) {
    return (
      <div style={styles.loaderContainer}>
        <PuffLoader color="#28d849" size={60} />
      </div>
    );
  }

  return (
    <div>
    <Toast />
    {/* Desktop View */}
    <div
      className="hidden md:flex flex-col justify-center items-center  min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="w-[450px]">
        <button
          className="text-lg cursor-pointer"
          onClick={() => window.history.back()}>
          <FaArrowLeft />
        </button>
      </div>
      
      {/* Widget */}
      <Widget type={type} />

      <div style={styles.poweredByText}>
        Powered by GSX Solutions Private Limited
      </div>
    </div>

    {/* Mobile View */}
    <div className="flex md:hidden flex-col justify-center items-center bg-white">
      <Widget fullScreen={true} type={type} />

      <div style={styles.poweredByText}>
        Powered by GSX Solutions Private Limited
      </div>
    </div>
  </div>
  );
}

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  poweredByText: {
    marginTop: '10px', // Spacing below the widget
    textAlign: 'center',
    fontSize: '10px', // Small font size
    fontWeight: 'normal',
    color: '#888', // Light gray color
  }
};
