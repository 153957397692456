import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UseSellSelectorStore from '../store/SellSelector';
import { FaCopy } from 'react-icons/fa';
import { showToast } from '../utils/toast';
const WalletCheckout = () => {
    const { globalCoin } = UseSellSelectorStore()
    const location = useLocation();
    const paymentData = location.state; // This is the data you passed
    const navigate = useNavigate()
    const handleConfirm = () => {
        navigate('/verify-transaction', { state: { reference_id: paymentData?.reference_id, chain: paymentData?.chain, fromAmount: paymentData?.fromAmount, fromCurrency: paymentData?.fromCurrency, toCurrency: paymentData?.toCurrency } })
    };
    const handleCopy = () => {
        navigator.clipboard.writeText(paymentData?.wallet);
        showToast("Deposit address copied to clipboard!");
    };
    return (
        <div className="flex flex-col h-[570px] min-w-[20rem] justify-between">
            {/* Content */}
            <div className="overflow-y-auto no-scrollbar p-4">
                {/* Wallet Information Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">Wallet Information</h2>

                    <div className="space-y-8">
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">You Sell</span>
                            <span className="text-sm font-medium text-gray-800 ">{globalCoin?.coin.toUpperCase()} {paymentData?.amount}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Chain</span>
                            <span className="text-sm font-medium text-gray-800 ">{paymentData?.chain}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Deposit Address</span>
                            <div className="flex items-center space-x-2">
                                {/* Deposit Address with wrapping */}
                                <span className="text-sm font-medium text-gray-800 max-w-[130px] md:max-w-[180px] text-ellipsis break-all">
                                    {paymentData?.wallet}
                                </span>


                                {/* Copy Button */}
                                <button onClick={handleCopy} className="text-blue-500 hover:text-blue-700">
                                    <FaCopy />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* UPI Notes Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">Wallet Checkout Notes</h2>

                    <ul className="space-y-5 list-disc pl-6 text-sm text-gray-700">
                        {
                            paymentData && paymentData?.cryptoNotes?.map((item, index) => (
                                <li key={index}>{item?.msg}</li>
                            ))
                        }

                    </ul>
                </div>
            </div>

            {/* Fixed Confirm Button */}
            <div className="p-4">
                <button
                    onClick={handleConfirm}
                    className="w-full bg-green-500 text-white py-3 rounded-lg text-md font-bold hover:bg-green-600 transition duration-200"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default WalletCheckout;
