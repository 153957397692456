import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FaTimes } from 'react-icons/fa';
import UseBuySelectorStore from '../store/BuySelector';
import UseSellSelectorStore from '../store/SellSelector';
const CurrencySelector = ({ isOpen, onClose, currencies, onSelect, setSearchTerm, searchTerm }) => {
    const isSellPage = window.location.pathname.includes('/sell');
    // Extract setGlobalCoin from the appropriate store
    const { setGlobalCurrency: setBuyGlobalCurrency } = UseBuySelectorStore();
    const { setGlobalCurrency: setSellGlobalCurrency } = UseSellSelectorStore();
    // Assign setGlobalCoin based on the URL
    const setGlobalCurrency = isSellPage ? setSellGlobalCurrency : setBuyGlobalCurrency;

    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="slide-full"
            unmountOnExit
        >
            <div className="absolute inset-0 bg-white z-40 p-4 overflow-y-auto">
                <div className="flex justify-between items-center p-4 mb-4">
                    <h2 className="text-lg font-medium">Select Fiat</h2>
                    <FaTimes className="text-xl cursor-pointer" onClick={onClose} />
                </div>
                <input
                    type="text"
                    placeholder="Search"
                    className="w-full mb-4 border rounded-md h-14 px-4 text-lg"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                />
                <ul className="overflow-y-auto">
                    {currencies && currencies.map((currency, index) => (
                        <li
                            key={index}
                            className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                            onClick={() => { setGlobalCurrency(currency); onSelect() }}
                        >
                            <div className="flex items-center">
                                <img src={currency?.flagUrl} alt={currency?.country} className="h-5 w-5 mr-3 rounded-full object-cover" />
                                <div>
                                    <span className="font-medium">{currency?.country}</span>
                                    <span className="block text-sm text-gray-500">
                                        {currency?.currencySymbol} - {currency?.fiatSymbol}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </CSSTransition>
    );
};

export default CurrencySelector;
