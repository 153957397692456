import React from 'react';
import digisvg from "../assets/svgs/digi.Bdzx4YsT.svg"
import signzysvg from "../assets/svgs/signzy.Dr9qxJQ2.svg"

import { FaClipboardQuestion } from "react-icons/fa6";
import {Link} from 'react-router-dom';
const BeforeKYC = () => {


    return (
        <div className="flex flex-col justify-between h-[570px] bg-white rounded-lg shadow-md p-6">
            {/* Main Content */}
            <div className="flex-1 space-y-8">
                {/* Icon */}
                <div className="flex flex-col flex-1 space-y-4 items-center text-center">
                <FaClipboardQuestion className='text-green-600 text-8xl' />

                    {/* Title */}
                    <h2 className="text-2xl font-semibold text-black">Complete your KYC</h2>

                    {/* Description */}
                    <p className="text-gray-600 font-semibold ">
                    We need to verify your details before you proceed. You'll need:
                    </p>
                </div>

                <ul className="space-y-2 list-none"> {/* Removed default list styling */}
                    <li className="flex items-center font-medium text-black">
                        <span className="text-blue-600 mr-3 text-3xl leading-none">•</span> Aadhaar number
                    </li>
                </ul>

                {/* Note */}
                <p className="text-xs text-gray-500">
                    Note: Follow instructions correctly to get KYC verified instantly, or it may be delayed by 24-48 hours.
                </p>

                {/* Powered By Section */}
                <div className="text-left mt-6">
                    <p className="text-sm text-gray-400 font-semibold">Powered by</p>
                    <div className="flex space-x-4">
                        <img src={digisvg} alt="DigiLocker" className="h-6 mt-2 " />
                        <img src={signzysvg} alt="Signzy" className="h-6 mt-1" />
                    </div>
                </div>
            </div>

            {/* Footer with Proceed Button */}
            <div className="mt-10">
                <Link to='/addAadhaar'
                    className="w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200 text-center block"
                >
                    Proceed
                </Link>
            </div>

        </div>
    );
};

export default BeforeKYC;
