import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { FaPaste } from 'react-icons/fa';
function VerifyTransaction() {
    const location = useLocation()
    const navigate = useNavigate()
    const paymentData = location.state
    const [txHash, setTxHash] = useState('')
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Set the respective state values
        if (name === 'txHash') {
            setTxHash(value);
        }
    };
    // Check form validation whenever inputs change
    const checkFormValidation = () => {
        if (
            txHash
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };
    // Handle form submission
    const handleSubmit = async () => {
        setIsSubmitting(true); // Disable the button during the request
        try {
            const response = await authFetch('/api/v1/offramp/verifyTransaction', {
                method: 'POST',
                body: {
                    txHash,
                    reference_id: paymentData?.reference_id,
                    fromAmount: paymentData?.fromAmount,
                    chain: paymentData?.chain,
                    fromCurrency: paymentData?.fromCurrency,
                    toCurrency: paymentData?.toCurrency
                },
            });

            if (response?.code === 200) {
                // Handle success (e.g., navigate to a different page or show a success message)
                showToast(response?.message, 'success');
                navigate('/confirm', { state: "sell" });
            } else {
                // Handle error (e.g., show an error message)
                showToast(response?.error, 'error');
            }
        } catch (error) {
            console.error('Error verifying:', error);
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    }
    const handlePasteClick = async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (text) {
                setTxHash(text);
            } else {
                showToast("Clipboard is empty or does not contain text.", 'warning');
            }
        } catch (error) {
            console.error("Failed to read clipboard contents: ", error);
            alert("Unable to paste. Please allow clipboard permissions and try again.");
        }
    };
    // Call validation function whenever account numbers, IFSC, or bank name change
    useEffect(() => {
        checkFormValidation();
    }, [txHash]);
    return (
        <div className="flex flex-col h-[570px] justify-between">
            <div className="flex-1 bg-white p-3 overflow-auto">
                {/* Form */}
                <div className="flex-1 space-y-6">
                    <p className="text-gray-600 text-sm">
                        Please enter your <strong>Transaction Hash.</strong>
                    </p>

                    {/* Transaction Hash Input with Paste Button */}
                    <div className="relative space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Transaction Hash</label>
                        <input
                            type="text"
                            name="txHash"
                            value={txHash}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 p-4 rounded-lg text-md focus:outline-none focus:border-green-600"
                        />
                        <button
                            onClick={handlePasteClick}
                            className="absolute right-3 top-1/2 text-gray-400 hover:text-green-500 focus:outline-none"
                        >
                            <FaPaste className="text-lg" />
                        </button>
                    </div>

                    {/* Verification Information */}
                    {/* <p className="text-sm text-gray-500">
                        We will send ₹1 to your account to verify your bank.
                    </p> */}
                </div>
            </div>

            {/* Footer with Next Button */}
            <div className="w-full p-4 bg-white">
                <button
                    onClick={handleSubmit}
                    className={`w-full py-4 rounded-lg text-white font-semibold ${isSubmitting
                        ? 'bg-gray-200' // Button turns gray when submitting
                        : isFormValid
                            ? 'bg-green-600 hover:bg-green-700'
                            : 'bg-green-300'
                        }`}
                    disabled={!isFormValid || isSubmitting}
                >
                    {isSubmitting ? 'Confirming...' : 'Confirm'}
                </button>
            </div>
        </div>
    );
}

export default VerifyTransaction