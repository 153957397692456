import React, { useEffect, useState } from 'react';
import { FiEdit2, FiMail, FiPhone, FiLock, FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import profilePic from "../assets/user.png";
import authFetch from '../utils/authFetch';
import PuffLoader from "react-spinners/PuffLoader";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useProfileStore from '../store/profileStore';
const Profile = () => {
    const { setGlobalProfile } = useProfileStore();
    const navigate = useNavigate()
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await authFetch('/api/v1/user/profile', {
                method: 'GET',
            });

            if (response) {
                setProfile(response.data);
                setGlobalProfile(response?.data)
            } else {
                navigate('/login')
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            navigate('/login')
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col h-[570px] items-center p-6 space-y-3 bg-gray-50 rounded-xl shadow-lg w-full max-w-md mx-auto min-w-[20rem] relative">
            {loading ?(
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 z-50">
                {/* PuffLoader for loading spinner */}
                <PuffLoader color="#28d849" size={60} />
            </div>
            ):(
               <>
                {/* Profile Header */}
            <h2 className="text-2xl text-gray-900 font-bold tracking-wide">Your Profile</h2>
            <div className="w-full bg-white p-6 rounded-xl shadow-md flex flex-col items-center border border-gray-200">
            <div className="relative">
            {!imageLoaded && (
                <div className="h-20 w-20 rounded-full border-2 border-gray-300 shadow-md bg-gray-300 animate-pulse"></div>
            )}
                <img
                    src={profilePic}
                    alt="Profile"
                    className={`h-20 w-20 rounded-full border-2 border-gray-300 shadow-md ${!imageLoaded ? "hidden" : ""}`}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(false)}
                />
                <span className="absolute bottom-1 right-1 bg-green-500 h-5 w-5 rounded-full border-2 border-white"></span> 
            </div>
                
            <div className="mt-2 p-5 bg-gray-50 rounded-lg w-full text-center">
                    <p className="text-gray-700 flex items-center text-base font-semibold">
                        <FiMail className="text-blue-500 text-lg mx-2 mt-1" /> {profile.email}
                    </p>
                    {profile.isPhoneAdded ? (
                         <p className="text-gray-700 flex items-center text-base font-semibold mt-1">
                            <FiPhone className="mx-2 text-lg text-blue-500" />
                            {profile.phone}
                        </p>
                    ) : (
                        <Link
                            to={'/addPhone'}
                            className="text-blue-600 text-sm font-semibold text-left w-full flex items-center hover:underline">
                            <FiPhone className="mx-2 text-lg text-blue-500" /> Add your phone
                        </Link>
                    )}
                    <div className="flex flex-col mt-1 w-full">
                        <Link
                            to={'/change-password'}
                            className="text-blue-600 text-sm font-semibold text-left w-full flex items-center hover:underline">
                            <FiLock className="mx-2 text-base text-blue-500" /> Change Password
                        </Link>
                    </div>
                </div>
            </div>

            {/*KYC Status*/}
            <div className="flex flex-col w-full bg-white items-center rounded-lg shadow-md p-4 ">
            <h3 className="text-lg font-bold text-gray-800 tracking-wide">KYC STATUS</h3>
            <div className="flex flex-col items-center text-center">
            {profile.isKycCompleted ? (
            <>
                <FiCheckCircle className="text-green-500 text-4xl my-6" />
                <p className="text-gray-700 font-semibold text-base">
                    You're all set! <span className="text-green-600 font-bold">KYC Verified</span>
                </p>
            </>
            ) : (
            <>
                <FiAlertCircle className="text-yellow-500 text-4xl mb-2" />
                <p className="text-gray-600 font-medium text-sm">
                    Verify now to complete your <span className="font-bold">KYC</span> and enjoy seamless access to our services.
                </p>
            </>
            )}
            </div>
                {!profile.isKycCompleted && (
                    <Link
                        to={profile.isPhoneAdded ? '/before-kyc' : '/addPhone'}
                        className="w-full mt-4 bg-green-600 text-white py-3 rounded-lg flex justify-center items-center text-lg font-bold">
                        Complete Basic KYC
                        <FiEdit2 className="ml-3 text-xl" />
                    </Link>
                )}

                {/*<KycProgress />

                Buy & Sell Limit
                <div className="w-full bg-white rounded-lg shadow-md p-4 space-y-6">
                    <div className="flex justify-between">
                        <p className="text-black text-md font-semibold">Buy limit:</p>
                        <p className="text-gray-500 text-md font-semibold">₹0 / month</p>
                    </div>
                    <div className="flex justify-between mt-2">
                        <p className="text-black text-md font-semibold">Sell limit:</p>
                        <p className="text-gray-500 text-md font-semibold">₹0 / month</p>
                    </div>
                </div>
            </div>

            Referral Earnings
            <div className="w-full bg-white rounded-lg shadow-md p-4 space-y-4">

                <div className="flex justify-between items-start">
                    <div>
                        <h3 className="text-md font-bold text-gray-800">My Referral Earnings</h3>
                        <div className="flex items-center mt-1">
                            <span className="text-green-500 text-xl mr-1">$</span>
                            <span className="text-gray-900 text-2xl font-semibold">0</span>
                            <span className="text-gray-700 ml-2">USDT</span>
                        </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                        <img src={dollerCoin} alt="Earnings Icon" className="h-24 w-24 object-contain" />
                    </div>
                </div>
                <p className="text-gray-800 mt-4 text-md">
                    Refer USDT Marketplace to contacts, earn when they transact
                </p>
                <a href="/" className="text-blue-600 text-md font-semibold mt-2 inline-block">
                    Learn more
                </a>
                <div className="relative mt-4 p-3 bg-gray-100 rounded-lg flex items-center">
                    <input
                        type="text"
                        value="https://usdtmarketplace.m...l/625737?appId=1"
                        readOnly
                        className="bg-transparent text-gray-600 font-bold w-full outline-none"
                    />
                    <button className="ml-2">
                        <FiCopy className="text-blue-600 text-lg" />
                    </button>
                </div>

                <div className="flex justify-between bg-green-600 mt-4 p-2 rounded-lg border border-green-700">
                    <button className="flex-1 flex justify-center items-center">
                        <FaWhatsapp className="h-6 w-6 text-white" />
                    </button>
                    <div className="w-1 h-8 bg-black mx-2"></div>
                    <button className="flex-1 flex justify-center items-center">
                        <FaT4elegramPlane className="h-6 w-6 text-white" />
                    </button>
                    <div className="w-1 h-8 bg-black mx-2"></div>
                    <button className="flex-1 flex justify-center items-center">
                        <FaTwitter className="h-6 w-6 text-white" />
                    </button>
                </div>*/}
            </div>
               </> 
            )}
        </div>
    );
};


export default Profile;
