import React from 'react';
import "../App.css";

const MainContainer = ({ children, fullScreen = false }) => {
  return (
    <div
      className={`relative bg-white shadow-md p-6 overflow-hidden ${fullScreen ? 'w-full h-full rounded-none p-0 m-0' : 'w-[450px] h-[700px] mx-4 rounded-lg'
        }`}
    >
      <div className="no-scrollbar h-full w-full overflow-y-auto">{children}</div>
    </div>
  );
};

export default MainContainer;
