import { create } from 'zustand';
const useCheckOnBoardStore = create((set) => ({
    isPhoneAdded: false,
    isBankAdded: false,
    isAadhaarAdded:false,
    setPhoneAdded: (value) => set({ isPhoneAdded: value }),
    setBankAdded: (value) => set({ isBankAdded: value }),
    setAadhaarAdded: (value) => set({ isAadhaarAdded: value }),
}));

export default useCheckOnBoardStore;