import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const OrderConfirmed = () => {
    const urlObj = new URL(window.location.href);
    // Use URLSearchParams to get the 'type' parameter
    const typeParam = urlObj.searchParams.get("type");
    const location = useLocation()
    const transactionType = location?.state
    return (
        <div className="h-[570px] flex flex-col items-center justify-between bg-white p-6">
            {/* Content */}
            <div className="flex flex-col items-center justify-center flex-grow">
                {/* Tick Icon */}
                <FaCheckCircle className="text-green-600 text-9xl mb-8" />

                {/* Order Confirmed Message */}
                <h1 className="text-3xl font-bold text-green-600 mb-4">Order Confirmed</h1>

                <p className="text-center text-green-600  mb-8 px-6 text-sm">
                    Please wait a moment while we confirm your transaction. You can check the status in your transaction history shortly.
                </p>
            </div>

            {/* Buttons */}
            <div className="space-y-4 w-full">
                {/* View Transaction Button */}
                <Link to={transactionType === "buy" || typeParam === "onramp" ? '/buy-history' : "/sell-history"}>
                    <button className="w-full border bg-green-600 border-green-500 text-white py-3 rounded-full text-center font-semibold hover:bg-green-700 transition duration-200">
                        View Transaction
                    </button>
                </Link>

            </div>
        </div>
    );
};

export default OrderConfirmed;
