import authFetch from "../utils/authFetch";
import { showToast } from "../utils/toast";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiX } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import useProfileStore from "../store/profileStore";
import UseSellSelectorStore from "../store/SellSelector";
import { PuffLoader } from "react-spinners";
import useRetryTransactionStore from "../store/retryTransactionStore";
const validateBankAccount = (accountNumber) => {
  const regex = /^\d{9,18}$/;
  return regex.test(accountNumber);
};

const RetryModal = ({ setIsModalOpen, getSellHistory }) => {
  const navigate = useNavigate();
  const { globalSelectedBank, setGlobalSelectedBank } = UseSellSelectorStore();
  const { retryTransaction } = useRetryTransactionStore();
  const { globalProfile } = useProfileStore();
  const [showSelectOptions, setSelectOptions] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [accountsDetails, setAccountDetails] = useState({
    accountNumber: "",
    confirmAccountNumber: "",
    accountName: "",
    ifscCode: "",
    bankName: "",
  });

  const fetchBankAccounts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await authFetch("/api/v1/offramp/getAllFiatAccount", {
        method: "GET",
      });
      if (response?.code === 200) {
        setBankAccounts(response?.data); // Update state with fetched bank accounts
      }
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
    } finally {
      setLoading(false); // Stop loading
      setSelectOptions(false);
    }
  };
  const handleBankSelect = (account) => {
    setGlobalSelectedBank(account); // Set the selected bank account object in global state
  };
  const handleProceed = async () => {
    setIsSubmitting(true);
    try {
      let body = {
        transactionId: retryTransaction?.transaction_id,
        sentFiatAccount: true,
        fiatAccountId: globalSelectedBank?.fiatAccountId,
        newBank: false,
      };
      const response = await authFetch(`/api/v1/offramp/offrampRetry`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        showToast(response?.data?.message, "success");
        setIsModalOpen(false)
        navigate("/sell-history");
        getSellHistory();
      } else {
        setIsModalOpen(false)
        navigate("/sell-history");
        getSellHistory();
      }
    } catch (err) {
      console.log("handleProceed", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "accountNumber" || name === "confirmAccountNumber") &&
      !/^\d*$/.test(value)
    ) {
      return;
    }
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let body = {
        transactionId: retryTransaction?.transaction_id,
        sentFiatAccount: false,
        newBank: true,
        fiatAccount: accountsDetails?.accountNumber,
        bankName: accountsDetails?.bankName,
        ifsc: accountsDetails?.ifscCode,
        accountName: accountsDetails?.accountName,
      };
      const response = await authFetch(`/api/v1/offramp/offrampRetry`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.code === 200) {
        showToast(response?.data?.message, "success");
        setIsModalOpen(false)
        navigate("/sell-history");
        getSellHistory();

      } else {
        setIsModalOpen(false)
        navigate("/sell-history");
        getSellHistory();
      }
    } catch (err) {
      console.log("handleSubmit", err);
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };
  // Check form validation whenever inputs change
  const checkFormValidation = () => {
    if (
      validateBankAccount(accountsDetails.accountNumber) && // Ensure account number is valid
      validateBankAccount(accountsDetails.confirmAccountNumber) && // Ensure confirm account number is valid
      accountsDetails.accountNumber === accountsDetails.confirmAccountNumber && // Ensure both account numbers match
      accountsDetails.ifscCode &&
      accountsDetails.bankName &&
      accountsDetails.accountName
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    checkFormValidation();
  }, [
    accountsDetails.accountNumber,
    accountsDetails.confirmAccountNumber,
    accountsDetails.ifscCode,
    accountsDetails.bankName,
    accountsDetails.accountName,
  ]);

  useEffect(() => {
    if (bankAccounts.length === 0) {
      setGlobalSelectedBank(null);
    }
  }, [bankAccounts, setGlobalSelectedBank])
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-gradient-to-br from-white  to-gray-100 rounded-2xl shadow-2xl w-96 p-5 transform transition-transform duration-300 scale-100'>
        <div
          className={`flex ${showForm && showSelectOptions
            ? "justify-between"
            : showSelectOptions
              ? "justify-end"
              : "justify-between"
            }`}>
          {(!showSelectOptions || (showForm && showSelectOptions)) && (
            <FiArrowLeft
              className='text-2xl text-gray-500 hover:text-black cursor-pointer transition duration-200'
              onClick={() => {
                setSelectOptions(true);
                setShowForm(false);
              }}
            />
          )}
          <FiX
            className='text-2xl text-gray-500 hover:text-black cursor-pointer transition duration-200'
            onClick={() => {
              setIsModalOpen(false);
              setShowForm(false);
            }}
          />
        </div>

        {loading ? (
          <div className='flex justify-center items-center min-h-60 '>
            <PuffLoader color='#3498db' size={60} />
          </div>
        ) : (
          <>
            {showSelectOptions && !showForm && (
              <div className='flex flex-col space-y-4 items-center min-h-60 justify-center'>
                <h2 className='text-lg font-bold text-gray-800'>
                  Choose an Option
                </h2>
                <p className='text-sm text-gray-600 text-center'>
                  Select or enter a bank account to proceed with your
                  transaction.
                </p>
                <button
                  onClick={() => fetchBankAccounts()}
                  className='w-full bg-gradient-to-r from-green-500 to-green-700 text-white py-3 rounded-xl text-md font-semibold shadow-md hover:from-green-600 hover:to-green-800 transform hover:scale-105 transition duration-200'>
                  Select Bank Account
                </button>
                {/* /add-bank */}
                <button
                  onClick={() => {
                    setShowForm(true);
                  }}
                  className='w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white py-3 rounded-xl text-md font-semibold shadow-md hover:from-blue-600 hover:to-blue-800 transform hover:scale-105 transition duration-200'>
                  Enter Bank Account
                </button>
              </div>
            )}
            {!showSelectOptions && (
              <div className='flex flex-col items-center space-y-4 overflow-auto max-h-60 w-full relative no-scrollbar'>
                {bankAccounts.length === 0 ? (
                  <>
                    {!showForm && (
                      <div className='p-4 bg-white shadow-lg rounded-lg text-center'>
                        <p className='py-4 font-semibold text-gray-900'>
                          You've not added any bank accounts
                        </p>
                        <button
                          onClick={() => setShowForm(true)}
                          className='w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white py-3 rounded-xl text-md font-semibold shadow-md hover:from-blue-600 hover:to-blue-800 transform hover:scale-105 transition duration-200'>
                          Enter Bank Account
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h2 className='sticky top-0 bg-white w-full text-center text-lg font-bold text-gray-800'>
                      Choose an Account
                    </h2>
                    {bankAccounts.map((account, index) => (
                      <div
                        key={index}
                        className={`p-2 bg-white shadow-lg w-full rounded-lg cursor-pointer flex justify-between items-center ${globalSelectedBank?.fiatAccountId ===
                          account?.fiatAccountId
                          ? "border-2 border-green-600"
                          : "border border-gray-300"
                          }`}
                        onClick={() => handleBankSelect(account)} // On click, select the entire bank object
                      >
                        <div>
                          <p className='font-semibold text-gray-800'>
                            {account?.bank_name}
                          </p>
                          <p className='text-gray-500 text-sm'>
                            Account Name: {account?.account_name}
                          </p>
                          <p className='text-gray-500 text-sm'>
                            Account Number: {account?.fiatAccount}
                          </p>
                          <p className='text-gray-500 text-sm'>
                            IFSC: {account?.ifsc}
                          </p>
                        </div>
                        {globalSelectedBank?.fiatAccountId ===
                          account?.fiatAccountId && (
                            <div className='text-green-600 font-semibold'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                version='1.1'
                                width='25'
                                height='25'
                                viewBox='0 0 256 256'>
                                <g
                                  style={{
                                    stroke: "none",
                                    strokeWidth: 0,
                                    fill: "none",
                                    opacity: 1,
                                  }}
                                  transform='translate(1.41 1.41) scale(2.81 2.81)'>
                                  <path
                                    d='M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z'
                                    style={{ fill: "rgb(44,198,23)" }}
                                  />
                                  <polygon
                                    points='37.33,69.32 37.33,69.32 37.33,47.17 37.33,47.17 66.85,33.97 77.93,45.05'
                                    style={{ fill: "rgb(36,173,21)" }}
                                  />
                                  <polygon
                                    points='37.33,69.32 15.14,47.13 26.22,36.05 37.33,47.17 63.78,20.68 74.86,31.75'
                                    style={{ fill: "rgb(255,255,255)" }}
                                  />
                                </g>
                              </svg>
                            </div>
                          )}
                      </div>
                    ))}

                  </>
                )}
                {globalSelectedBank !== null &&
                  bankAccounts.length !== null && (
                    <button
                      onClick={handleProceed}
                      className={`sticky bottom-0 w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold transition duration-200 ${globalSelectedBank === null || isSubmitting
                        ? "opacity-50"
                        : "hover:bg-green-700"
                        }`}
                      disabled={globalSelectedBank === null || isSubmitting}>
                      {isSubmitting ? "Retring..." : "Retry Transaction"}
                    </button>
                  )}
              </div>
            )}
            {showForm && (
              <div className='flex-1 px-4 space-y-6 overflow-auto max-h-96 w-full no-scrollbar'>
                <h2 className='text-sm font-semibold text-center text-gray-800'>
                  Make sure to enter <strong>your own</strong> account, not
                  someone else's.
                </h2>
                <div>
                  <label className='text-sm font-semibold text-gray-700'>
                    Bank Account Number
                  </label>
                  <input
                    type='text'
                    name='accountNumber'
                    value={accountsDetails.accountNumber}
                    onChange={handleInputChange}
                    className={`w-full border p-2 rounded-lg text-md focus:outline-none 
                 ${!validateBankAccount(accountsDetails.accountNumber) &&
                        accountsDetails.accountNumber
                        ? "border-red-500 focus:border-red-600"
                        : "border-gray-300 focus:border-green-600"
                      }`}
                    inputMode='numeric'
                  />
                  <div className='h-1'>
                    {!validateBankAccount(accountsDetails.accountNumber) &&
                      accountsDetails.accountNumber && (
                        <p className='text-red-500 text-xs'>
                          Account number must be 9 to 18 digits long.
                        </p>
                      )}
                  </div>
                </div>
                <div>
                  <label className='text-sm font-semibold text-gray-700'>
                    Confirm Bank Account Number
                  </label>
                  <input
                    type='text'
                    name='confirmAccountNumber'
                    value={accountsDetails.confirmAccountNumber}
                    onChange={handleInputChange}
                    className={`w-full border p-2 rounded-lg text-md focus:outline-none
                  ${accountsDetails.confirmAccountNumber !==
                        accountsDetails.accountNumber &&
                        accountsDetails.confirmAccountNumber
                        ? "border-red-500 focus:border-red-600"
                        : "border-gray-300 focus:border-green-600"
                      }`}
                    inputMode='numeric'
                  />
                  <div className='h-1'>
                    {accountsDetails.confirmAccountNumber !==
                      accountsDetails.accountNumber &&
                      accountsDetails.confirmAccountNumber && (
                        <p className='text-red-500 text-xs'>
                          Account numbers do not match.
                        </p>
                      )}
                  </div>
                </div>
                <div>
                  <label className='text-sm font-semibold text-gray-700'>
                    Account Name
                  </label>
                  <input
                    type='text'
                    name='accountName'
                    value={accountsDetails.accountName}
                    onChange={handleInputChange}
                    className='w-full border border-gray-300 p-2 rounded-lg text-md focus:outline-none focus:border-green-600'
                  />
                </div>
                <div>
                  <label className='text-sm font-semibold text-gray-700'>
                    IFSC Code
                  </label>
                  <input
                    type='text'
                    name='ifscCode'
                    value={accountsDetails.ifscCode}
                    onChange={handleInputChange}
                    className='w-full border border-gray-300 p-2 rounded-lg text-md focus:outline-none focus:border-green-600'
                  />
                </div>
                <div>
                  <label className='text-sm font-semibold text-gray-700'>
                    Bank Name
                  </label>
                  <input
                    type='text'
                    name='bankName'
                    value={accountsDetails.bankName}
                    onChange={handleInputChange}
                    className='w-full border border-gray-300 p-2 rounded-lg text-md focus:outline-none focus:border-green-600'
                  />
                </div>
                <div className='w-full p-2 bg-white'>
                  <button
                    onClick={handleSubmit}
                    className={`w-full py-3 rounded-lg text-white font-semibold bg-gray-200
               ${isSubmitting
                        ? "bg-gray-200"
                        : isFormValid
                          ? "bg-green-500 hover:bg-green-600"
                          : "bg-gray-200"
                      }`}
                    disabled={!isFormValid || isSubmitting}>
                    {isSubmitting ? "Retring..." : "Retry Transaction"}
                  </button>
                </div>
              </div>
            )}
          </>
        )}

      </div>
    </div>
  );
};

export default RetryModal;
