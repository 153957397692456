// authFetch.js
import { showToast } from './toast';
import config from '../config' // Adjust the path according to your folder structure
const authFetch = async (url, options = {}) => {
    try {
        // Get the token from local storage (or another secure place)
        const token = localStorage.getItem('userToken');

        // Prepare headers
        const headers = {
            'Content-Type': 'application/json',
            ...options.headers,
            ...(token && { Authorization: `Bearer ${token}` }),
        };

        // Prepare the full URL
        const fullUrl = `${config.API_BASE_URL}${url}`;
        // Make the request using fetch
        const response = await fetch(fullUrl, {
            method: options.method || 'GET',
            headers,
            body: options.body ? JSON.stringify(options.body) : undefined,
        });
        if (response?.status === 400 || response?.status === 500) {
            const res = await response.json();
            showToast(res?.error, 'error');
            return;
        }
        if (response.status === 403 || response.status === 401) {
            const res = await response.json();
            localStorage.removeItem('userToken');
            showToast(res?.error, 'error');
            return;
        }


        // Parse the JSON response
        const data = await response.json();

        // // Check if the response is not OK (other errors)
        // if (!response.ok) {
        //     throw new Error(data.error || 'An error occurred');
        // }

        return data;
    } catch (error) {
        throw error;
    }
};

export default authFetch;
