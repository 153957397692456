import React, { useEffect, useState } from 'react';
import Header from './Header';
import MainContainer from './MainContainer';
import Buy from './Buy';
import Sell from './sell';
import SideMenu from './SideMenu';
import Login from './Login';
import Signup from './Signup';
import Profile from './Profile';
import Logout from './Logout';
import AddPhone from './AddPhone';
import Kyc from './Kyc';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute
import PublicRoute from './PublicRoute'; // Import PublicRoute
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import WalletAddress from './WalletAddress';
import IMPSCheckout from './IMPSCheckout';
import UPICheckout from './UPICheckout';
import OrderConfirmed from './OrderConfirmed';
import BuyHistory from './BuyHistory';
import HistoryDetail from './HistoryDetail';
import BankAccounts from './BankAccount';
import AddBankAccount from './AddBankAccount';
import BeforeKYC from './BeforeKYC';
import SelectBank from './SelectBank';
import WalletCheckout from './WalletCheckout';
import useProfileStore from '../store/profileStore';
import SellHistory from './SellHistory';
import OTPVerification from './OTPVerification';
import ForgetPassword from './ForgetPassword';
import ChangePassword from './ChangePassword';
import VerifyTransaction from './VerifyTransaction';
import AddAadhaar from './AddAadhaar';
const Widget = ({ fullScreen = false, type = "offramp" }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { setProfile } = useProfileStore()
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    if (userToken) {
      setProfile()
    }
  }, [])

  return (
    <div className='relative'>
      <MainContainer fullScreen={fullScreen}>
        <Router>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} type={type} />
          <Header onMenuClick={toggleMenu} fullScreen={fullScreen} />
          <div className="mt-4">
            <Routes>
              {/* <Route path="/" element={type === "onramp" ? (<Buy />) : (<Sell />)} /> */}
              <Route path="/" element={<Sell />} />
              {/* <Route path="/buy" element={<Buy />} /> */}
              <Route path="/sell" element={<Sell />} />
              <Route path="/otp" element={<OTPVerification />} />
              <Route path="/wallet-address" element={<WalletAddress />} />
              <Route path="/forget-password" element={<ForgetPassword />}/>
              <Route
                path="/login"
                element={<PublicRoute component={Login} redirectTo="/profile" />}
              />
              <Route
                path="/signup"
                element={<PublicRoute component={Signup} redirectTo="/profile" />}
              />
              <Route
                path="/imps-checkout"
                element={<PrivateRoute component={IMPSCheckout} redirectTo="/login" />}
              />
              <Route
                path="/upi-checkout"
                element={<PrivateRoute component={UPICheckout} redirectTo="/login" />}
              />
              <Route
                path="/wallet-checkout"
                element={<PrivateRoute component={WalletCheckout} redirectTo="/login" />}
              />
              {/* <Route
                path="/buy-history"
                element={<PrivateRoute component={BuyHistory} redirectTo="/login" />}
              /> */}
              <Route
                path="/sell-history"
                element={<PrivateRoute component={SellHistory} redirectTo="/login" />}
              />
              <Route
                path="/history-detail"
                element={<PrivateRoute component={HistoryDetail} redirectTo="/login" />}
              />
              <Route
                path="/confirm"
                element={<PrivateRoute component={OrderConfirmed} redirectTo="/login" />}
              />
              <Route
                path="/verify-transaction"
                element={<PrivateRoute component={VerifyTransaction} redirectTo="/login" />}
              />
              <Route
                path="/bank"
                element={<PrivateRoute component={BankAccounts} redirectTo="/login" />}
              />
              <Route
                path="/fiataccount"
                element={<PrivateRoute component={SelectBank} redirectTo="/login" />}
              />
              <Route
                path="/add-bank"
                element={<PrivateRoute component={AddBankAccount} redirectTo="/login" />}
              />
              <Route
                path="/profile"
                element={<PrivateRoute component={Profile} redirectTo="/login" />}
              />
              <Route
                path="/logout"
                element={<PrivateRoute component={Logout} redirectTo="/login" />}
              />
              <Route
                path="/addPhone"
                element={<PrivateRoute component={AddPhone} redirectTo="/login" />}
              />
              <Route
                path="/kyc"
                element={<PrivateRoute component={Kyc} redirectTo="/login" />}
              />
              <Route
                path="/before-kyc"
                element={<PrivateRoute component={BeforeKYC} redirectTo="/login" />}
              />
              <Route
                path="/addAadhaar"
                element={<PrivateRoute component={AddAadhaar} redirectTo="/login" />}
              />
              <Route
                path="/change-password"
                element={<PrivateRoute component={ChangePassword} redirectTo="/login" />}
              />
              {/* Redirect unknown routes to the default view */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </MainContainer>
    </div>
  );
};

export default Widget;

