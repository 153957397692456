import React from 'react';
import { useLocation } from 'react-router-dom';
import { convertToIST } from '../utils/dateTimeFormat';
import {LuRepeat} from 'react-icons/lu';
import useRetryModalStore from '../store/retryModalStore';
import useRetryTransactionStore from '../store/retryTransactionStore';
import RetryModal from '../Modal/RetryModal';
const HistoryDetail = () => {
    const location = useLocation()
    const { setIsModalOpen, isModalOpen } = useRetryModalStore();
    const { retryTransaction, setRetryTransaction } = useRetryTransactionStore();
    const { transaction, transactionType } = location?.state
    return (
        <>
            <div className="p-4 space-y-6 bg-gradient-to-b from-gray-100 to-white shadow-lg rounded-lg">
            {/* Transaction Info */}
            <div className="space-y-6">
                <div className="space-y-2">
                <div className='flex justify-between items-center'>
              <p className='text-lg font-semibold'>
                You Sent {transaction?.fromCurrency} {transaction?.fromAmount}{" "}
                to {transaction?.toAmount} {transaction?.toCurrency}
              </p>
              {transaction?.status === "FAILED" && (
                <div className='relative group'>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setRetryTransaction(transaction);
                      setIsModalOpen(true);
                    }}
                    className=' rounded-lg shadow-sm border-2 flex p-2'>
                    <LuRepeat className='text-lg text-gray-500' />
                  </button>
                  <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-600 text-white text-sm font-medium rounded-lg px-2 py-1 shadow-lg'>
                    Retry
                  </div>
                </div>
              )}
            </div>
                    <p className="text-sm text-yellow-500 uppercase font-semibold">Chain: {transaction?.chain}</p>
                </div>

                <div className="space-y-1 ">
                    <p className="text-md font-semibold">Fiat Status</p>
                    <p className={`text-sm font-semibold ${transaction.status === "SUCCESS" ? 'text-green-500' : transaction.status === 'PENDING' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {transaction.status}
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Current Status</p>
                    <p className={`text-sm font-semibold text-gray-700`}>
                        {transaction?.FiatMoneyTransferStatus}
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Crypto Status</p>
                    <p className={`text-sm font-semibold ${transaction.txStatus === "SUCCESS" ? 'text-green-500' : transaction.txStatus === 'PENDING' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {transaction.txStatus}
                    </p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Current Status</p>
                    <p className={`text-sm font-semibold text-gray-700`}>
                        {transaction?.cryptoTransferStatus}
                    </p>
                </div>
                {
                    transactionType === "buy" && (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Payment Method</p>
                            <p className="text-sm font-semibold text-gray-700">{transaction?.paymentMethodType}</p>
                        </div>
                    )
                }

                <div className="space-y-1">
                    <p className="text-md font-semibold">Reference ID</p>
                    <p className="text-sm font-semibold text-gray-700">{transaction?.reference_id}</p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Transaction ID</p>
                    <p className="text-sm font-semibold text-gray-700">{transaction?.transaction_id}</p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Blockchain Hash</p>
                    <p className="text-sm font-semibold text-gray-700">{transaction?.txHash}</p>
                </div>
                {
                    transactionType === 'buy' ? (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Deposit Address</p>
                            <p className="text-sm font-semibold text-gray-700">{transaction?.depositAddress}</p>
                        </div>
                    ) : (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Fiat Account </p>
                            <p className="text-sm font-semibold text-gray-700">{transaction?.fiatAccountId}</p>
                        </div>
                    )
                }

                <div className="space-y-1">
                    <p className="text-md font-semibold">Rate</p>
                    <p className="text-sm font-semibold text-gray-700">{transaction.rate}</p>
                </div >

                <div className="space-y-1">
                    <p className="text-md font-semibold">Date</p>
                    <p className="text-sm font-semibold text-gray-700">{convertToIST(transaction?.date).date}</p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Time</p>
                    <p className="text-sm font-semibold text-gray-700">{convertToIST(transaction?.date).time}</p>
                </div>
             </div>
             </div>
            {isModalOpen && <RetryModal setIsModalOpen={setIsModalOpen} />}
        </>
       
    );
};

export default HistoryDetail;


