import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FaTimes } from 'react-icons/fa';
import NetworkSelector from './NetworkSelector';
import UseBuySelectorStore from '../store/BuySelector';
import UseSellSelectorStore from '../store/SellSelector';
const CoinSelector = ({ isOpen, onClose, coins, onSelect, setSearchTerm, searchTerm }) => {
    // Conditionally choose the store based on the URL
    const isSellPage = window.location.pathname.includes('/sell');
    const [isNetworkSelectorOpen, setIsNetworkSelectorOpen] = useState(false);
    // Extract setGlobalCoin from the appropriate store
    const { setGlobalCoin: setBuyGlobalCoin } = UseBuySelectorStore();
    const { setGlobalCoin: setSellGlobalCoin } = UseSellSelectorStore();

    // Assign setGlobalCoin based on the URL
    const setGlobalCoin = isSellPage ? setSellGlobalCoin : setBuyGlobalCoin;
    // When a coin is selected, open the network selector
    const handleCoinSelect = (coin) => {
        setGlobalCoin(coin)
        setIsNetworkSelectorOpen(true);  // Open the Network Selector
    };

    // Handle network selection from NetworkSelector
    const handleNetworkSelect = () => {
        setIsNetworkSelectorOpen(false); // Close Network Selector
        onClose(); // Close Coin Selector
    };

    return (
        <>
            <CSSTransition
                in={isOpen && !isNetworkSelectorOpen}
                timeout={300}
                classNames="slide-full"
                unmountOnExit
            >
                <div className="absolute inset-0 bg-white z-40 p-4 overflow-y-auto">
                    <div className="flex justify-between items-center p-4 mb-4">
                        <h2 className="text-lg font-medium">Select Coin</h2>
                        <FaTimes className="text-xl cursor-pointer" onClick={onClose} />
                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full mb-4 border rounded-md h-14 px-4 text-lg"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                    />
                    <ul className="overflow-y-auto">
                        {coins && coins.map((coin) => (
                            <li
                                key={coin?.coin}
                                className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCoinSelect(coin)}  // Trigger NetworkSelector
                            >
                                <div className="flex items-center">
                                    <img src={coin?.coinIcon} alt={coin?.coinName} className="h-5 w-5 mr-3 rounded-full object-cover" />
                                    <div>
                                        <span className="font-medium">{coin.coinName}</span>
                                        <span className="block text-sm text-gray-500 uppercase">
                                            {coin?.coin}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </CSSTransition>

            {/* Network Selector */}
            <NetworkSelector
                isOpen={isNetworkSelectorOpen}
                onClose={() => setIsNetworkSelectorOpen(false)}
                onSelect={handleNetworkSelect}  // Handle network selection
            />
        </>
    );
};

export default CoinSelector;
