import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAadhaarStore from "../store/addAadhaarStore";
import authFetch from "../utils/authFetch";
import { FaInfoCircle } from "react-icons/fa";
import aadhaarImage from "../assets/Aadhaar_Logo.svg.png";
import { showToast } from "../utils/toast";
import PuffLoader from "react-spinners/PuffLoader";

const AddAadhaar = () => {
  const navigate = useNavigate();
  const { globalAadhaar, setGlobalAadhaar, clearGlobalAadhaar } =
    useAadhaarStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Check form validation whenever inputs change
  const checkFormValidation = (value) => {
    if (value.length === 12 && /^\d{12}$/.test(value)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const handleAddAadhaarOTP = async (e) => {
    e.preventDefault();
    let body = {
      aadharNumber: globalAadhaar,
    };
    setIsSubmitting(true);
    try {
      const response = await authFetch(`/api/v1/user/kyc/otp`, {
        method: "POST",
        body: body,
      });
      if (response.code === 200) {
        showToast("OTP is sent to your Phone", "success");
        onSuccess(response.data.id); 
      } else {
        showToast(response.error || "Failed to send OTP to phone.", "error");
      }
    } catch (error) {
      // showToast('An error occurred. Please try again.', 'error');
      console.error("handleAddPhoneOTP", error);
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };
  const onSuccess = async (id) => {
    try {
      navigate("/otp", { state: { type: "addAadhaar" , id } }); // Navigate to OTP page
    } catch (err) {
      console.error("onSuccess", err);
    }
  };
  useEffect(() => {
      clearGlobalAadhaar();
  }, []);

  return (
    <div className='flex flex-col h-[570px] min-w-[20rem] w-full p-6 items-center justify-between'>
     
      {isSubmitting ? (
        <div className='flex justify-center items-center mt-10'>
          <PuffLoader color='#3498db' size={60} />
        </div>
      ) : (
        <div className='bg-white shadow-lg rounded-lg p-6 w-full max-w-lg'>
          {/* Heading & Info */}
          <div className='flex items-center justify-between'>
            <h2 className='text-2xl font-bold text-gray-800'>
              Enter Aadhaar Number
            </h2>
            <FaInfoCircle
              className='text-gray-500 cursor-pointer hover:text-gray-700'
              title='Your Aadhaar will be verified securely.'
            />
          </div>
          <p className='text-gray-600 text-sm mt-1'>
            We will verify your Aadhaar with an OTP sent to your registered
            mobile number.
          </p>

          {/* Aadhaar Illustration */}
          <div className='flex justify-center my-8'>
            <img
              src={aadhaarImage}
              alt='Aadhaar Verification'
              className='h-20'
            />
          </div>

          {/* Form */}
          <form className='flex flex-col space-y-6'>
            {/* Input Field */}
            <div className='w-full relative'>
              <input
                type='number'
                value={globalAadhaar}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value.length > 12) value = value.slice(0, 12);
                  setGlobalAadhaar(value);
                  checkFormValidation(value);
                }}
                placeholder='Enter Aadhaar number'
                className='w-full px-4 py-4 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold h-14 transition-transform transform focus:scale-105'
                required
              />
              {/*{errors.value && (
              <p className='text-red-500 text-sm mt-1'>{errors.message}</p>
            )}*/}
            </div>
          </form>
        </div>
      )}

      {/* Submit Button */}
      <div className='w-full p-4 bg-white'>
        <button
          className={`w-full py-4 rounded-lg text-white font-semibold ${
            isSubmitting
              ? "bg-gray-200"
              : isFormValid
              ? "bg-green-500 hover:bg-green-600"
              : "bg-gray-200"
          }`}
          disabled={!isFormValid || isSubmitting}
          onClick={handleAddAadhaarOTP}>
          {isSubmitting ? "Adding..." : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default AddAadhaar;
